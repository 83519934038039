    body {
    }

    body {
      margin: 0;
    }

    .areaHeader {
      //background-color: #EEE;
      background-color: #FFF;
      height: 80px;
    }

    .buttonTop {
      margin: 10px 0 0 10px;
      border: 1px solid #000;
      width:  60px;
      height: 60px;
      position: absolute;
      left: 10px;
    }

    .areaProject {
      margin: 10px 10px 0 0;
      //border: 1px solid #000;
      width: 160px;
      height: 60px;
      position: absolute;
      right: 10px;
    }

    .areaUserInfo {
      margin: 10px 10px 0 0;
      //border: 1px solid #000;
      width: 160px;
      height: 60px;
      position: absolute;
      right: 10px;
    }

    .areaUserInfoName {
      //border: 1px solid #000;
      text-align: left;
    }

    .areaUserInfoSignOut {
      //border: 1px solid #000;
      color: #888;
      text-align: Right;
      position: absolute;
      right: 0;
    }

    .areaTabBar {
      background-color: #888;
      height: 40px;
      width: 100%;
    }

    .buttonTab {
      border: 1px solid #000;
      background-color: #EEE;
      width: 25%;
    }

    .buttonTabSelected {
      background-color: #FFF;
      width: 25%;
    }

    .buttonTabDisable {
      background-color: #777;
      width: 25%;
    }

    .buttonCom {
      margin: 30px auto 20px auto;
      border: 1px solid #FFF;
      background-color: #EEE;
      position:relative;
      width: 35%;
      height: 45px;
      text-align: center;
      //vertical-align: middle;
      //margin: auto;
      //display: flex;
    }

    .buttonComSelected {
      margin: 30px auto 20px auto;
      border: 1px solid #BBB;
      background-color: #CCC;
      position:relative;
      width: 35%;
      height: 45px;
      text-align: center;
      //vertical-align: middle;
      //margin: auto;
      //display: flex;
    }

    .buttonComDisable {
      margin: 30px auto 20px auto;
      border: 1px solid #888;
      background-color: #777;
      position:relative;
      width: 35%;
      height: 45px;
      text-align: center;
      //vertical-align: middle;
      //margin: auto;
      //display: flex;
    }

    .buttonComText {
      margin: 10px 0 0 0;
    }

    .selectedBaseTitle {
      margin: 10px auto 5px auto;
      text-align: center;
    }

    .selectedBase {
      margin: 0 auto 15px auto;
      background-color: #f0f8ff;
      width: 92%;
      height: 30px;
      text-align: center;
    }

    .areaGraph {
      margin: 20px auto 15px auto;
      background-color: #f0f8ff;
      width: 60%;
      height: 160px;
      text-align: center;
    }

    .inputText {
      margin: 10px auto 15px 10%;
      background-color: #f0f8ff;
      width: 80%;
    }

    .selectText {
      margin: 10px auto 15px 10%;
      background-color: #f0f8ff;
      width: 80%;
    }

    .resultList {
      margin: 15px auto;
    }
    .resultList table{
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
    }
    
    .resultList table tr{
      border-bottom: solid 1px #eee;
      cursor: pointer;
    }
/*    
    .resultList table tr:hover{
      background-color: #d4f0fd;
    }
*/    
    .resultList table th,table td{
      text-align: center;
      width: 25%;
      padding: 15px 0;
    }

    .selectedBaseItem {
      background-color: #8F8;
    }
    
